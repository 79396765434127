<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title><a @click="goToList">{{ site.title }}</a>&nbsp;-&nbsp;{{ entity.title }}</v-card-title>
                <v-card-text>
                    <EntityTabLinks />
                    
                    <v-alert outlined type="error" v-if="!settings.payment || !settings.payment.allow_online">
                        Для того, чтобы клиенты могли оплачивать заказы картой на сайте необходимо 
                        заполнить выбрать платежную систему и заполнить <a @click="goToSettings">настройки онлайн оплаты</a>
                    </v-alert>

                    <template v-if="deliveries">
                        <!-- ENTITY_DELIVERY_TYPE.PICKUP -->
                        <template v-for="(delivery, deliveryIndex) in deliveries">
                            <v-card class="pa-6 mb-4" :key="`pickup-${delivery.id}`" v-if="delivery.type === ENTITY_DELIVERY_TYPE.PICKUP">
                                <v-row class="pb-4">
                                    <v-col>
                                        <div class="v-title">Самовывоз</div>
                                    </v-col>
                                    <v-col class="d-flex justify-end">
                                        <v-switch
                                            v-model="delivery.enabled"
                                            class="pa-0 ma-0"
                                            label="Активен"
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-divider />
                                <v-row class="pt-4">
                                    <v-col>
                                        <v-textarea 
                                            v-model="delivery.comment"
                                            outlined
                                            label="Описание"
                                            rows="1"
                                            auto-grow
                                            hide-details>
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-row>
                                            <v-col>
                                                <b>Способы оплаты</b>
                                            </v-col>
                                        </v-row>
                                        <template v-for="payment of ENTITY_PAYMENT_TYPE">
                                            <v-row :key="`pickup-payment-${payment}`">
                                                <v-col>
                                                    <v-switch
                                                        v-model="delivery.payments[payment]"
                                                        class="pa-0 ma-0"
                                                        :label="ENTITY_PAYMENT_TYPE_TITLE[payment]"
                                                        hide-details
                                                    />
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <b>Минимальный заказ</b>
                                        <v-text-field
                                            :value="delivery.formatedMinOrderPrice"
                                            @input="setDeliveryMinOrderPrice(deliveryIndex, $event)"
                                            class="mt-2"
                                            outlined
                                            label="Минимальная сумма заказа"
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </template>
                        <!-- ENTITY_DELIVERY_TYPE.PICKUP -->

                        <!-- ENTITY_DELIVERY_TYPE.DELIVERY -->
                        
                        <template v-if="!isTypeDelivery && !isTypeDeliveryZone && !geoJsonForm">
                            <v-card class="pa-6 mb-4">
                                <v-row class="pb-4">
                                    <v-col>
                                        <div class="v-title">Доставка</div>
                                    </v-col>
                                </v-row>
                                <v-divider />
                                <v-row class="pt-4">
                                    <v-col>
                                        <p>
                                            Настройте зоны доставки, минимальный заказ и стоимость доставки для заказов на сайте.
                                        </p>
                                        <p>
                                            <b>Если вы осуществляете доставку только в определенные зоны:</b><br>
                                            - Нажмите кнопку «Задать зоны доставки»;<br>
                                            - Нарисуйте зоны в конструкторе карт и загрузите их в Маркеторию;<br>
                                            - Укажите минимальный заказ, стоимость доставки и зависимость стоимости доставки от стоимости заказа.<br>
                                        </p>
                                        <p>
                                            <b>Если зона доставки не ограничена:</b><br>
                                            - Нажмите кнопку «Неограниченная зона»;<br>
                                            - Укажите минимальный заказ, стоимость доставки и зависимость стоимости доставки от стоимости заказа.<br>
                                        </p>
                                        <div class="py-4 d-flex justify-space-around flex-wrap">
                                            <v-btn
                                                class="mb-2"
                                                color="primary"
                                                @click="geoJsonForm = true"
                                            >Задать зоны доставки</v-btn>
                                            <v-btn
                                                class="mb-2"
                                                color="primary"
                                                @click="addTypeDelivery"
                                            >Неограниченная доставка</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </template>
                        <template v-else-if="isTypeDelivery">
                            <template v-for="(delivery, deliveryIndex) in deliveries">
                                <v-card class="pa-6 mb-4" :key="`delivery-${deliveryIndex}`" v-if="delivery.type === ENTITY_DELIVERY_TYPE.DELIVERY">
                                    <v-row class="pb-4">
                                        <v-col>
                                            <div class="v-title">Доставка</div>
                                        </v-col>
                                        <v-col class="d-flex justify-end">
                                            <v-switch
                                                v-model="delivery.enabled"
                                                class="pa-0 ma-0"
                                                label="Активен"
                                                hide-details
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-divider />
                                    <v-row class="py-4">
                                        <v-col cols="12">
                                            <v-textarea 
                                                v-model="delivery.comment"
                                                outlined
                                                label="Описание"
                                                rows="1"
                                                auto-grow
                                                hide-details>
                                            </v-textarea>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="4">
                                            <v-row>
                                                <v-col>
                                                    <b>Способы оплаты</b>
                                                </v-col>
                                            </v-row>
                                            <template v-for="payment of ENTITY_PAYMENT_TYPE">
                                                <v-row :key="`pickup-payment-${payment}`">
                                                    <v-col>
                                                        <v-switch
                                                            v-model="delivery.payments[payment]"
                                                            class="pa-0 ma-0"
                                                            :label="ENTITY_PAYMENT_TYPE_TITLE[payment]"
                                                            hide-details
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="8">
                                            <b>Минимальный заказ и стоимость доставки</b>
                                            <div class="v-description">
                                                Задайте минимальный заказ и стоимость доставки. Стоимость доставки может зависеть от стоимости заказа.<br>
                                            </div>
                                            <v-text-field
                                                :value="delivery.formatedMinOrderPrice"
                                                @input="setDeliveryMinOrderPrice(deliveryIndex, $event)"
                                                class="mt-2"
                                                outlined
                                                label="Минимальная сумма заказа"
                                                hide-details
                                            />
                                            <v-simple-table v-if="delivery.conditions.length">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th>Минимальный заказ</th>
                                                            <th>Cтоимость доставки</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in delivery.conditions" :key="`delivery-condition-${deliveryIndex}-${index}`">
                                                            <td>{{ priceFormatedFrom(item.min_order_price) }}</td>
                                                            <td>{{ priceFormated(item.price) }}</td>
                                                            <td align="right">
                                                                <v-icon color="primary" @click="editCondition(deliveryIndex, index)">mdi-pencil</v-icon>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>

                                            <v-btn
                                                color="primary"
                                                class="mt-2"
                                                @click="addCondition(deliveryIndex)"
                                            >Добавить условие</v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-divider />
                                    <div class="pt-4 d-flex justify-end">
                                        <v-btn
                                            color="error"
                                            @click="remove()"
                                        >Очистить условия доставки</v-btn>
                                    </div>
                                </v-card>
                            </template>
                        </template>
                        <template v-else-if="geoJsonForm">
                            <v-card class="pa-6 mb-4">
                                <v-row class="pb-4">
                                    <v-col>
                                        <div class="v-title pb-4">
                                            Задайте зоны доставки
                                        </div>
                                        <v-divider />
                                        <div class="py-4">
                                            - Воспользуйтесь <a href="https://yandex.ru/map-constructor/" target="_blank">конструктором зон</a>, чтобы нарисовать зоны доставки на карте;<br>
                                            - Если зоны накладываются одна на другую, то порядок их отрисовки можно изменить при помощи поля «Сортировка»;<br>
                                            - Если адрес гостя будет попадать в несколько зон, мы возьмем для расчетов зону с максимальным значением «Сортировка»;<br>
                                            - Экспортируйте карту в формате GEO JSON и загрузите полученный файл.<br>
                                        </div>
                                        <v-file-input
                                            label="GEO JSON"
                                            outlined
                                            prepend-icon="mdi-file"
                                            hide-details
                                            @change="setGeoJson"
                                        />
                                    </v-col>
                                </v-row>
                                <v-divider />
                                <v-row class="pt-4">
                                    <v-col class="d-flex justify-end">
                                        <v-btn
                                            color="error"
                                            @click="geoJsonForm = false"
                                        >Назад</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </template>
                        <template v-else-if="isTypeDeliveryZone">
                            <v-card class="pa-6 mb-4">
                                <v-row>
                                    <v-col>
                                        <div class="v-title pb-4">Зоны доставки</div>
                                        <div class="py-4">
                                            <v-switch
                                                v-model="settings.delivery.allow_delivery_outside_zones"
                                                class="pa-0 ma-0"
                                                label="Разрешить оформление заказа, если адрес не попадает в границы зон доставки"
                                                hide-details
                                            />
                                        </div>
                                        <v-divider />
                                        <div class="py-4">
                                            - Если разрешена доставка вне границ зон доставки, то стоимость доставки оговаривается с клиентом отдельно;<br>
                                            - Если зоны накладываются одна на другую, то порядок их отрисовки можно изменить при помощи поля «Сортировка»;<br>
                                            - Если адрес гостя будет попадать в несколько зон, мы возьмем для расчетов зону с максимальным значением «Сортировка»;<br>
                                            - Каждой зоне доставки можно задать минимальный заказ, стоимость доставки и зависимость стоимости доставки от стоимости заказа.
                                        </div>
                                        <v-divider />
                                        <div class="pt-4 d-flex justify-end">
                                            <v-btn
                                                color="error"
                                                @click="remove()"
                                            >Удалить зоны</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <template v-for="(delivery, deliveryIndex) in deliveries">
                                <v-card class="pa-6 mb-4" :key="`delivery-zone-${deliveryIndex}`" v-if="delivery.type === ENTITY_DELIVERY_TYPE.DELIVERY_ZONE">
                                    <v-row class="pb-4">
                                        <v-col cols="12" md="3">
                                                <v-text-field
                                                    v-model="delivery.sort"
                                                    label="Сортировка"
                                                    hide-details
                                                />
                                        </v-col>
                                        <v-col cols="12" md="7">
                                                <v-text-field
                                                    v-model="delivery.title"
                                                    label="Заголовок"
                                                    hide-details
                                                />
                                        </v-col>
                                        <v-col cols="12" md="2" class="d-flex justify-end">
                                            <v-switch
                                                v-model="delivery.enabled"
                                                class="pa-0 ma-0"
                                                label="Активен"
                                                hide-details
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-divider />
                                    <v-row class="py-4">
                                        <v-col cols="12">
                                            <v-textarea 
                                                v-model="delivery.comment"
                                                outlined
                                                label="Описание"
                                                rows="1"
                                                auto-grow
                                                hide-details>
                                            </v-textarea>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="4" xl="3">
                                            <img :src="pictureGeoZone(delivery)">
                                        </v-col>
                                        <v-col cols="12" md="6" lg="3" xl="3">
                                            <v-row>
                                                <v-col>
                                                    <b>Способы оплаты</b>
                                                </v-col>
                                            </v-row>
                                            <template v-for="payment of ENTITY_PAYMENT_TYPE">
                                                <v-row :key="`pickup-payment-${payment}`">
                                                    <v-col>
                                                        <v-switch
                                                            v-model="delivery.payments[payment]"
                                                            class="pa-0 ma-0"
                                                            :label="ENTITY_PAYMENT_TYPE_TITLE[payment]"
                                                            hide-details
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-col>
                                        <v-col cols="12" md="12" lg="5" xl="6">
                                            <b>Минимальный заказ и стоимость доставки</b>
                                            <div class="v-description">
                                                Задайте минимальный заказ и стоимость доставки. Стоимость доставки может зависеть от стоимости заказа.<br>
                                            </div>
                                            <v-text-field
                                                :value="delivery.formatedMinOrderPrice"
                                                @input="setDeliveryMinOrderPrice(deliveryIndex, $event)"
                                                class="mt-2"
                                                outlined
                                                label="Минимальная сумма заказа"
                                                hide-details
                                            />
                                            <v-simple-table v-if="delivery.conditions.length">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th>Минимальный заказ</th>
                                                            <th>Cтоимость доставки</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in delivery.conditions" :key="`delivery-condition-${deliveryIndex}-${index}`">
                                                            <td>{{ priceFormatedFrom(item.min_order_price) }}</td>
                                                            <td>{{ priceFormated(item.price) }}</td>
                                                            <td align="right">
                                                                <v-icon color="primary" @click="editCondition(deliveryIndex, index)">mdi-pencil</v-icon>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>

                                            <v-btn
                                                color="primary"
                                                class="mt-2"
                                                @click="addCondition(deliveryIndex)"
                                            >Добавить условие</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </template>
                        </template>
                        <!-- ENTITY_DELIVERY_TYPE.DELIVERY -->
                    </template>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Минимальный заказ и стоимость доставки
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <p>Гость сможет оформить заказ, только если стоимость выше минимальной.</p>
                                <p>Если доставка бесплатная — укажите «0» в поле «Стоимость доставки».</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :value="editDeliveryCondition.formatedMinOrderPrice"
                                    @input="setMinOrderPrice"
                                    outlined
                                    label="Минимальная сумма заказа"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :value="editDeliveryCondition.formatedPrice"
                                    @input="setPrice"
                                    outlined
                                    label="Стоимость доставки"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="saveCondition()"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        class="ml-3"
                        @click="removeCondition()"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import axios from 'axios';
    import store from '../../store';
    import { yandexMap, ymapMarker, loadYmap } from 'vue-yandex-maps';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import EntityTabLinks from '../../components/sites/EntityTabLinks';

    import { ENTITY_DELIVERY_TYPE, ENTITY_DELIVERY_TYPE_TITLE, ENTITY_PAYMENT_TYPE, ENTITY_PAYMENT_TYPE_TITLE } from '../../vars';
    import { priceFormated, priceFormatedFrom } from '../../helpers';

    export default {
        name: 'EntityDeliveries',

        components: {
            EntityTabLinks,
            yandexMap, 
            ymapMarker
        },

        data() {
            return {
                ENTITY_DELIVERY_TYPE,
                ENTITY_DELIVERY_TYPE_TITLE,
                ENTITY_PAYMENT_TYPE, 
                ENTITY_PAYMENT_TYPE_TITLE,
                priceFormated,
                priceFormatedFrom,
                emptyDelivery: {
                    entity_id: this.$route.params.id,
                    type: null,
                    title: '',
                    comment: '',
                    formatedMinOrderPrice: 0,
                    min_order_price: 0,
                    payments: {
                        [ENTITY_PAYMENT_TYPE.CASH]: false,
                        [ENTITY_PAYMENT_TYPE.CARD]: false,
                        [ENTITY_PAYMENT_TYPE.ONLINE]: false,
                    },
                    zone: null,
                    coordinates: [],
                    options: {},
                    conditions: [],
                    sort: 0,
                    enabled: false,
                },
                emptyDeilveryCondition: {
                    entity_id: this.$route.params.id,
                    entity_delivery_id: null,
                    deliveryIndex: -1,
                    index: -1,
                    price: 0,
                    formatedPrice: 0,
                    min_order_price: 0,
                    formatedMinOrderPrice: 0,
                },
                removedDeliveries: [],
                removedDeliveryConditions: [],

                editModal: false,
                editDeliveryCondition: {
                    deliveryIndex: -1,
                    index: -1,
                    price: null,
                    formatedPrice: null,
                    min_order_price: null,
                    formatedMinOrderPrice: null,
                },

                geoJsonForm: false,
                geoJson: '',
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                site: state => state.entity
            }),
            ...mapState('entities', {
                entity: state => state.entity
            }),
            ...mapState('entitySetting', {
                settings: state => state.entity
            }),
            ...mapState('entityDeliveries', {
                deliveries: state => state.entities
            }),
            isAdmin() {
                return this.user.admin;
            },
            isTypeDelivery() {
                return !!this.deliveries.filter(item => item.type === ENTITY_DELIVERY_TYPE.DELIVERY).length;
            },
            isTypeDeliveryZone() {
                return !!this.deliveries.filter(item => item.type === ENTITY_DELIVERY_TYPE.DELIVERY_ZONE).length;
            },
            getDeliveriesTypeDelivery() {
                for(let index = 0; index < this.deliveries.length; index ++) {
                    this.deliveries[index].index = index;
                    this.deliveries[index].formatedMinOrderPrice = this.deliveries[index].min_order_price / 100;
                }
                return this.deliveries.filter(item => item.type === ENTITY_DELIVERY_TYPE.DELIVERY);
            },
            types() {
                const types = [];
                for(const key in ENTITY_DELIVERY_TYPE) {
                    types.push({
                        title: ENTITY_DELIVERY_TYPE_TITLE[ENTITY_DELIVERY_TYPE[key]],
                        value: ENTITY_DELIVERY_TYPE[key]
                    })
                }
                return types;
            }
        },

        async mounted() {
            const { site_id, id } = this.$route.params;
            if(site_id) {
                await store.dispatch('sites/get', { id: site_id });
            }
            if(site_id && id) {
                await store.dispatch('entities/get', { site_id, id });
                await store.dispatch('entitySetting/get', { entity_id: id });
                await store.dispatch('entityDeliveries/fetch', { entity_id: id });

                if(this.deliveries.length === 0) {
                    store.commit('entityDeliveries/SET_ENTITY', {
                        ...this.emptyDelivery,
                        title: 'Самовывоз',
                        type: ENTITY_DELIVERY_TYPE.PICKUP
                    });
                    await store.dispatch('entityDeliveries/save', {entity_id: this.$route.params.id});
                    await store.dispatch('entityDeliveries/fetch', {entity_id: this.$route.params.id});
                }
            }
            await loadYmap({
                lang: "ru_RU",
                coordorder: "longlat",
                version: "2.1"
            });
        },

        methods: {
            async setGeoJson(file) {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = function() {
                    this.geoJson = reader.result;
                    this.parseGeoJson();
                    this.geoJsonForm = false;
                }.bind(this);
                reader.onerror = function() {
                    console.log(reader.error);
                };
            },
            async parseGeoJson() {
                if(this.geoJson) {
                    this.geoJson = JSON.parse(this.geoJson);
                    let count = 0, latSum = 0, lonSum = 0;
                    for(const geoZone of this.geoJson.features) {
                        if(geoZone.geometry.type === 'Polygon') {
                            this.addTypeDeliveryZone({ 
                                title: geoZone.properties.description, 
                                coordinates: geoZone.geometry.coordinates[0], 
                                options: { 
                                    fillColor: geoZone.properties.fill, 
                                    fillOpacity: geoZone.properties['fill-opacity'], 
                                    strokeColor: geoZone.properties.stroke, 
                                    strokeOpacity: geoZone.properties['stroke-opacity'], 
                                    strokeWidth: geoZone.properties['stroke-width'] 
                                }
                            });
                        }
                    }
                }
            },
            pictureGeoZone(geoZone) {
                const options = [
                    'l=map',
                    'size=300,300',
                    `pl=c:${geoZone.options.strokeColor.substr(1)}${this._getOpacity(geoZone.options.strokeOpacity)},f:${geoZone.options.fillColor.substr(1)}${this._getOpacity(geoZone.options.fillOpacity)},w:${geoZone.options.strokeWidth},${this._getCoordinates(geoZone.coordinates)}`,
                ];
                return `https://static-maps.yandex.ru/1.x/?${ options.join('&') }`
            },
            _getCoordinates(coordinates) {
                return coordinates.map(item => item.join(',')).join(',');
            },
            _getOpacity(opacity) {
                return Number(Math.round(255 * opacity)).toString(16);
            },

            setPrice(value) {
                this.editDeliveryCondition.formatedPrice = value;
                this.editDeliveryCondition.price = value * 100;
            },
            setMinOrderPrice(value) {
                this.editDeliveryCondition.formatedMinOrderPrice = value;
                this.editDeliveryCondition.min_order_price = value * 100;
            },
            setDeliveryMinOrderPrice(deliveryIndex, value) {
                this.deliveries[deliveryIndex].formatedMinOrderPrice = value;
                this.deliveries[deliveryIndex].min_order_price = value * 100;
            },
            addTypeDelivery() {
                this.deliveries.push(
                    JSON.parse(JSON.stringify({
                        ...this.emptyDelivery,
                        title: 'Доставка',
                        type: ENTITY_DELIVERY_TYPE.DELIVERY
                    }))
                );
            },
            addTypeDeliveryZone({ title, coordinates, options }) {
                this.deliveries.push(
                    JSON.parse(JSON.stringify({
                        ...this.emptyDelivery,
                        title,
                        coordinates,
                        options,
                        type: ENTITY_DELIVERY_TYPE.DELIVERY_ZONE
                    }))
                );
            },
            addCondition(deliveryIndex) {
                const index = this.deliveries[deliveryIndex].conditions.length;
                this.deliveries[deliveryIndex].conditions.push(
                    JSON.parse(JSON.stringify({
                        ...this.emptyDeilveryCondition,
                        deliveryIndex,
                        index
                    }))
                );
                this.editCondition(deliveryIndex, index);
            },
            editCondition(deliveryIndex, index) {
                const item = this.deliveries[deliveryIndex].conditions[index];
                this.editDeliveryCondition = JSON.parse(JSON.stringify({
                    ...item,
                    formatedPrice: item.price / 100,
                    formatedMinOrderPrice: item.min_order_price / 100,
                }));
                this.editModal = true;
            },
            saveCondition() {
                this.editModal = false;
                const { deliveryIndex, index } = this.editDeliveryCondition;
                this.deliveries[deliveryIndex].conditions[index].min_order_price = this.editDeliveryCondition.min_order_price;
                this.deliveries[deliveryIndex].conditions[index].price = this.editDeliveryCondition.price;
            },
            removeCondition() {
                this.editModal = false;
                const { deliveryIndex, index } = this.editDeliveryCondition;
                if(this.deliveries[deliveryIndex].conditions[index].id) {
                    this.removedDeliveryConditions.push(this.deliveries[deliveryIndex].conditions[index]);
                }
                this.deliveries[deliveryIndex].conditions.splice(index, 1);
                store.commit('entityDeliveries/SET_ENTITIES', this.deliveries);
            },
            async save() {
                try {
                    store.commit('entitySetting/SET_ENTITY', this.settings);
                    await store.dispatch('entitySetting/save', { entity_id: this.$route.params.id });

                    for(const condition of this.removedDeliveryConditions) {
                        if(condition.id) {
                            await store.dispatch('entityDeliveryConditions/delete', { entity_id: this.$route.params.id, ...condition });
                        }
                    }
                    for(const delivery of this.removedDeliveries) {
                        if(delivery.id) {
                            await store.dispatch('entityDeliveries/delete', { entity_id: this.$route.params.id, id: delivery.id });
                        }
                    }

                    let sort = 0;
                    for(const delivery of this.deliveries.sort((a, b) => a.sort - b.sort)) {
                        delivery.sort = sort;
                        sort += 10;
                        store.commit('entityDeliveries/SET_ENTITY', delivery);
                        await store.dispatch('entityDeliveries/save', {entity_id: this.$route.params.id});
                    }
                    await store.dispatch('entityDeliveries/fetch', {entity_id: this.$route.params.id});
                    this.goBack();
                } catch (error) {
                    console.log(error);
                    alert('Ошибка сохранения');
                }
            },
            async remove() {
                try {
                    this.geoJsonForm = false;
                    this.removedDeliveries = this.deliveries.filter(item => item.type !== ENTITY_DELIVERY_TYPE.PICKUP);
                    store.commit('entityDeliveries/SET_ENTITIES', this.deliveries.filter(item => item.type === ENTITY_DELIVERY_TYPE.PICKUP));
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goToSettings() {
                const { site_id } = this.$route.params;
                this.$router.push(`/sites/${site_id}/entities/${this.$route.params.id}/setting`);
            },
            goToList() {
                const { site_id } = this.$route.params;
                if(site_id) {
                    this.$router.push(`/sites/${site_id}/entities`);
                } else {
                    this.$router.push('/sites');
                }
            },
            goBack() {
                const { site_id, id } = this.$route.params;
                if(site_id && id) {
                    this.$router.push(`/sites/${site_id}/entities/${id}/detail`);
                } else if(site_id) {
                    this.$router.push(`/sites/${site_id}/entities`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>

<style lang="scss">
    .deliveries {
        &__map {
            width: 100%;
            height: 400px;

            &__wrapper {
                width: 100%;
                height: 400px;
                border-radius: 4px;
                overflow: hidden;
            }
        }
    }
</style>