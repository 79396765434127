export const ENTITY_PAYMENT_TYPE = Object.freeze({
    CASH: 'cash',
    CARD: 'card',
    ONLINE: 'online'
});

export const ENTITY_PAYMENT_TYPE_TITLE = Object.freeze({
    [ENTITY_PAYMENT_TYPE.CASH]: 'Наличными при получении',
    [ENTITY_PAYMENT_TYPE.CARD]: 'Картой при получении',
    [ENTITY_PAYMENT_TYPE.ONLINE]: 'Картой на сайте'
});

export const ENTITY_DELIVERY_TYPE = Object.freeze({
    PICKUP: 'pickup',
    DELIVERY: 'delivery',
    DELIVERY_ZONE: 'delivery_zone'
});

export const ENTITY_DELIVERY_TYPE_TITLE = Object.freeze({
    [ENTITY_DELIVERY_TYPE.PICKUP]: 'Самовывоз',
    [ENTITY_DELIVERY_TYPE.DELIVERY]: 'Доставка',
    [ENTITY_DELIVERY_TYPE.DELIVERY_ZONE]: 'Доставка по зонам'
});

export const DISCOUNT_TYPE = Object.freeze({
    PERCENT: 'percent',
    // PRESENT: 'present',
    // ONE_PLUS_ONE: 'one_plus_one',
});

export const DISCOUNT_TYPE_TITLE = Object.freeze({
    [DISCOUNT_TYPE.PERCENT]: 'Скидка в процентах',
    // [DISCOUNT_TYPE.PRESENT]: 'Блюдо в подарок',
    // [DISCOUNT_TYPE.ONE_PLUS_ONE]: 'Два по цене одного',
});

export const FEEDBACK_FORM_TITLE = Object.freeze({
    'home-connect': 'Главная -> Подключить',
    'home-download': 'Главная -> Презентация',
    'home-demo': 'Главная -> Демо',
    'home-contacts': 'Главная -> Контакты',
});

export const BLOCK_TYPE = Object.freeze({
    MENU: 'menu',
    HEADER: 'header',
    DESCRIPTION: 'description',
    IMAGES: 'images',
    NEWS: 'news', 
    CONTACTS: 'contacts'
});
