<template>
    <AppTabLinks :links="list" />
</template>

<script>
    import { mapState } from 'vuex';
    import AppTabLinks from '@/components/AppTabLinks'

    export default {
        components: {
            AppTabLinks
        },
        computed: {
            ...mapState('auth', ['user']),
            // ...mapState('sites', ['entity']),
            isAdmin() {
                return this.user.admin;
            },
            list() {
                return [
                    {
                        title: 'Карточка',
                        url: `/sites/${this.$route.params.site_id}/entities/${this.$route.params.id}/detail`,
                        disabled: !this.$route.params.id
                    },
                    {
                        title: 'Редактировать',
                        url: this.$route.params.id ? `/sites/${this.$route.params.site_id}/entities/${this.$route.params.id}/edit` : `/sites/${this.$route.params.site_id}/entities/edit`
                    },
                    // {
                    //     title: 'Расписание работы',
                    //     url: `/sites/${this.$route.params.site_id}/entities/${this.$route.params.id}/schedules`,
                    //     disabled: !this.$route.params.id,
                    //     // fill_required: !(this.entity.schedules && this.entity.schedules.length)
                    // },
                    {
                        title: 'Настройки',
                        url: `/sites/${this.$route.params.site_id}/entities/${this.$route.params.id}/setting`,
                        disabled: !this.$route.params.id
                    },
                    {
                        title: 'Доставка',
                        url: `/sites/${this.$route.params.site_id}/entities/${this.$route.params.id}/deliveries`,
                        disabled: !this.$route.params.id,
                        // fill_required: !(this.entity.deliveries && this.entity.deliveries.length)
                    },
                ]
            }
        },
    }
</script>
